@import "../../../styles/variables.module";
.hireWriter {
  .writerWrpper {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background-image: url("../../" + $bg-base-path + "/line-testimonial.svg");
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      background-size: 100%;
      top: -20px;
    }
    [class*="slick-arrow"] {
      transform: translateY(-100%);
    }
    .outlineSecondaryBtn {
      top: 30px;
      background: transparent;
      color: $theme-custom-color;
      z-index: 1;
      &:hover {
        background-color: $secondary-color;
      }
    }
    .writerBox {
      padding: 16px;
      border-radius: 10px;
      cursor: pointer;
      background: $white-color;
      border: 1px solid transparent;
      position: relative;
      top: 0;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      &:hover {
        -webkit-box-shadow: 0px 20px 40px rgba(38, 56, 77, 0.2);
        box-shadow: 0px 20px 40px rgba(38, 56, 77, 0.2);
        border-color: $secondary-color;
        top: -20px;
      }
      .writerImg {
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        @include extra_large {
          height: 180px;
        }
        img {
          object-fit: cover;
          width: 100% !important;
          height: 100% !important;
        }
      }
      .writerDetail {
        padding-top: 16px;
        position: relative;
        .star {
          height: 18px;
          & > span {
            & > span {
              margin-left: 8px;
              height: 18px;
              @include extra_large {
                margin-left: 4px;
              }
            }
          }
          img {
            max-width: fit-content;
          }
        }
        .name {
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 0;
          @include extra_large {
            font-size: 16px;
          }
          a {
            color: $theme-custom-color;
            -webkit-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .education {
          color: #888888;
          font-size: 14px;
          margin-bottom: 25px;
          @include mobile_device {
            margin-bottom: 10px;
          }
        }
        .subDetail {
          margin-top: 16px;
          margin-bottom: 8px;
          li {
            margin-bottom: 8px;
            width: 50%;
            align-items: flex-start;
            span {
              margin-left: 10px;
              font-size: 16px;
              @include extra_large {
                margin-left: 4px;
              }
              @include large_device {
                margin-left: 6px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    [class^="slick-slider"] {
      padding-top: 0;
      [class*="-slider"] {
        padding: 0;
        @include large_device {
          overflow: hidden;
          padding-bottom: 30px;
        }
        [class*="arrow"] {
          @include large_device {
            top: auto;
            bottom: -4px;
            z-index: 9;
            &::before {
              width: 30px;
              height: 20px;
              background-size: 25px;
            }
          }
        }
        [class*="prev"] {
          @include extra_large {
            left: -30px;
          }
          @include large_device {
            left: calc(50% - 80px);
          }
        }
        [class*="next"] {
          @include extra_large {
            right: -30px;
          }
          @include large_device {
            right: calc(50% - 80px);
          }
        }
      }
    }
    [class^="slick-slide "] {
      padding: 20px 10px 50px;
      @include large_device {
        padding-bottom: 30px;
      }
    }
    [class*="dots"] {
      bottom: 10px;
      left: 0;
      right: 0;
      z-index: 3;
      @include large_device {
        bottom: 0;
      }
    }
  }
}
